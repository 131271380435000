import React, { useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const Image = ({ src, ...props }) => {
    const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "default-image.png"}) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allFile( filter: { internal: { mediaType: { regex: "images/" } } } ) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

    const match = useMemo(() => (
        data.allFile.edges.find(({ node }) => src === node.relativePath)
    ), [data, src])

    return (
        match ?            
            <Img
                fluid={match.node.childImageSharp.fluid}
                {...props}
            /> 
            : 
            <Img 
              fluid={data.file.childImageSharp.fluid} 
              {...props} 
            />
    )
}

export default Image